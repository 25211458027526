/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import {
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";

import CardProduct from "./cards/CardProduct";
import ModalProduct from "./modals/modalProduct/ModalProduct";
import { groupProductsByFirstWord } from "../utils/functions";
import CardMultiProduct from "./cards/CardMultiProduct";
import ModalMultiProduct from "./modals/modalProduct/ModalMultiProduct";
import { IceCreamForDelivery, IceCreamForOnPremiseConsumption, IceCreamsToTakeAwayConsuming } from "../utils/staticData";
interface Props {
  addCart: Function;
  storeSection: StoreSections | undefined;
  includeShop: boolean;
  cakeCustomizationData: any;
  volcanicCandelData: any;
  ceramicCandelData: any;
  when_shop_create_preorder: boolean;
  enable_to_sale_in_negative: boolean;
}

export default function ProductForCategory({ addCart, storeSection, includeShop, cakeCustomizationData,
  volcanicCandelData, ceramicCandelData, when_shop_create_preorder, enable_to_sale_in_negative
}: Props) {

  // storeSection?.data.forEach(prod => {
  //   if (prod.availableAddons.length > 0) {
  //     console.log(prod)
  //   }
  // })

  const [showModalMultiProduct, setShowModalMultiProduct] = useState<boolean>(false);

  const [multipleProducts, setMultipleProducts] = useState<{
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  }[]>();

  const [selectedMultiProduct, setSelectedMultiProduct] = useState<{
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  }>();


  useEffect(() => {
    const groupedProducts = groupProductsByFirstWord(storeSection?.data!);

    setMultipleProducts(groupedProducts)
  }, [])

  const [showModal, setShowModal] = useState<boolean>(false);
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  const showMultiProduct = (value: {
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  }) => {
    setSelectedMultiProduct(value);
    setShowModalMultiProduct(true);
  };

  if (!storeSection) {
    return <></>;
  }

  const repeatedWords: string[] = []

  const IceCramSection = [
    {
      title: "Para consumo en el local",
      products: storeSection.data.filter(product => IceCreamForOnPremiseConsumption.some(item => item === product.id))
    },
    {
      title: "Para llevar consumiendo",
      products: storeSection.data.filter(product => IceCreamsToTakeAwayConsuming.some(item => item === product.id))
    },
    {
      title: "Para domicilio",
      products: storeSection.data.filter(product => IceCreamForDelivery.some(item => item === product.id))
    },
  ]


  return (
    <>
      <div key={storeSection.id} className={"mt-8"}>
        <h5
          className="font-bold text-xl my-2 text-left text-slate-500"
          id={storeSection.title}
        >
          {storeSection.title}
        </h5>

        <div className="grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 md:gap-x-6   sm:gap-y-10 lg:grid-cols-4 lg:gap-y-6 lg:gap-x-6 xl:grid-cols-5 xl:gap-y-6 xl:gap-x-6">
          {storeSection.title !== "Helados"
            ? storeSection.data.map((product, index) => {

              if (storeSection.title === "Yogures") {
                // Codigo para mostrar el card de multiproduct
                if (!(multipleProducts?.some(e => e.repeatedWord === product.name.split(" ")[0]))) {
                  return (
                    <>
                      {
                        //product.id === 19722 Es el producto personalizacion de tartas
                        //product.id === 20118 Es el producto personalizacion con texto de chocolate
                        //product.id === 23350 Es el producto vela volcanica
                        //product.id === 29734 Es el producto vela de cera
                        // (product.id !== 19722 && product.id !== 23350 && product.id !== 29734) && (
                        (product.id !== 20118 && product.id !== 23350 && product.id !== 29734) && (
                          <CardProduct
                            product={product}
                            onClick={() => showProduct(product)}
                            priceSystemId={1}
                            cardWidth={"w-full"}
                            addCart={addCart}
                            includeShop={includeShop}
                            key={index}
                            when_shop_create_preorder={when_shop_create_preorder}
                            enable_to_sale_in_negative={enable_to_sale_in_negative}
                          />
                        )
                      }

                    </>
                  )
                } else {

                  if (!(repeatedWords.some(e => e === product.name.split(" ")[0]))) {
                    repeatedWords.push(product.name.split(" ")[0])

                    return (
                      <CardMultiProduct
                        multipleProduct={multipleProducts.find(e => e.repeatedWord === product.name.split(" ")[0])!}
                        onClick={() => showMultiProduct(multipleProducts.find(e => e.repeatedWord === product.name.split(" ")[0])!)}
                        priceSystemId={1}
                        cardWidth={"w-full"}
                        addCart={addCart}
                        includeShop={includeShop}
                        key={index}
                      />
                    )
                  }
                }
              } else {
                return (
                  <>
                    {
                      //product.id === 19722 Es el producto personalizacion de tartas
                      //product.id === 23350 Es el producto vela volcanica
                      //product.id === 29734 Es el producto vela de cera
                      (product.id !== 19722 && product.id !== 23350 && product.id !== 29734) && (
                        <CardProduct
                          product={product}
                          onClick={() => showProduct(product)}
                          priceSystemId={1}
                          cardWidth={"w-full"}
                          addCart={addCart}
                          includeShop={includeShop}
                          key={index}
                          when_shop_create_preorder={when_shop_create_preorder}
                          enable_to_sale_in_negative={enable_to_sale_in_negative}
                        />
                      )
                    }
                  </>
                )
              }

            })
            : IceCramSection.map((section, index) => {
              return (
                <CardMultiProduct
                  multipleProduct={
                    {
                      repeatedWord: section.title,
                      repeatedProducts: section.products
                    }
                  }
                  onClick={() => showMultiProduct({
                    repeatedWord: section.title,
                    repeatedProducts: section.products
                  })}
                  priceSystemId={1}
                  cardWidth={"w-full"}
                  addCart={addCart}
                  includeShop={includeShop}
                  key={index}
                />
              )
            })}

        </div>
      </div>

      {showModal && (
        <ModalProduct
          product={seletedProduct}
          onClick={() => setShowModal(false)}
          addCart={addCart}
          includeShop={includeShop}
          cakeCustomizationData={cakeCustomizationData}
          volcanicCandelData={volcanicCandelData}
          ceramicCandelData={ceramicCandelData}
          when_shop_create_preorder={when_shop_create_preorder}
          enable_to_sale_in_negative={enable_to_sale_in_negative}
        />
      )}

      {showModalMultiProduct && (
        <ModalMultiProduct
          multipleProduct={selectedMultiProduct!}
          onClick={() => setShowModalMultiProduct(false)}
          addCart={addCart}
          includeShop={includeShop}
          cakeCustomizationData={cakeCustomizationData}
          volcanicCandelData={volcanicCandelData}
          ceramicCandelData={ceramicCandelData}
          when_shop_create_preorder={when_shop_create_preorder}
          enable_to_sale_in_negative={enable_to_sale_in_negative}


        />
      )}
    </>
  );
}
