/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RadioGroup } from "@headlessui/react"
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, Key } from "react"
import { VariationsProd } from "../../../interfaces/ServerInterfaces"


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const VariationAttributes = (
    { selectedAttribute,
        setSelectedAttribute,
        attributeArray,
        resultVariation,
        attribute
    }: {
        selectedAttribute: string,
        setSelectedAttribute: any,
        attributeArray: string[],
        resultVariation: VariationsProd[],
        attribute: string
    }) => {

    return (
        <div className="mt-2">
            <div className="flex items-center justify-between">
                <h4 className="text-sm font-medium text-gray-900">{attribute}</h4>
            </div>
            <RadioGroup value={selectedAttribute} onChange={setSelectedAttribute} className="mt-4">
                <RadioGroup.Label className="sr-only">Seleccione un color</RadioGroup.Label>
                <div className="grid grid-cols-4 gap-4">
                    {attributeArray.map((attrib: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | ((bag: {}) => ReactElement<any, string | JSXElementConstructor<any>>) | null | undefined, key: Key | null | undefined) => (
                        <RadioGroup.Option
                            key={key}
                            value={attrib}
                            className={({ active }) =>
                                classNames(
                                    resultVariation.filter((variation: { attributes: any[] }) => {
                                        const attributes = variation.attributes.filter(attribute => attribute.value === attrib)
                                        return attributes.length > 0
                                    }).length >= 1
                                        ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                                        : 'cursor-not-allowed bg-gray-50 text-gray-200 pointer-events-none',
                                    active ? 'ring-2 ring-blue-900' : '',
                                    'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium capitalize hover:bg-gray-50 focus:outline-none sm:flex-1'
                                )
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <RadioGroup.Label as="span">{attrib}</RadioGroup.Label>
                                    {resultVariation.filter((variation: { attributes: any[] }) => {
                                        const attributes = variation.attributes.filter(attribute => attribute.value === attrib)
                                        return attributes.length > 0
                                    }).length >= 1 ? (
                                        <span
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-blue-900' : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-md'
                                            )}
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                        >
                                            <svg
                                                className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="none"
                                                stroke="currentColor"
                                            >
                                                <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                            </svg>
                                        </span>
                                    )}
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>

    )
}

export default VariationAttributes

