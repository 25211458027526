import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import searchReducer from "./slices/resultsReducer";
import SessionSlice from "./slices/SessionSlice";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import OrdersSlice from "./slices/OrdersSlice";
import FormSlice from "./slices/FormSlice";
import InitSlice from "./slices/InitSlice";

const rootReducer = combineReducers({
  UI: searchReducer,
  session: SessionSlice,
  orders: OrdersSlice,
  form: FormSlice,
  init: InitSlice
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "init"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  middleware: (getDefaultMiddelware) =>
    getDefaultMiddelware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
