import { useContext, useState, useEffect } from "react";
import { ProductInterface } from "../interfaces/ServerInterfaces";
import { ProductInfoContex } from "./modals/modalProduct/ModalProduct";


export const CarrouselProductImages = ({ product }: { product: ProductInterface }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? product?.images.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === product?.images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleSetImage = (index: number) => {
        setCurrentImageIndex(index);
    };

    const {
        resultVariation
    } = useContext(ProductInfoContex);

    useEffect(() => {
        if (resultVariation !== null && resultVariation?.image !== null) {
            const index = product.images.findIndex(image => image.src === resultVariation?.image?.src);
            if (index !== -1) {
                handleSetImage(index);
            }
        }
    }, [resultVariation]);


    return (
        <div className="relative items-center">
            <div className="overflow-hidden">
                <img
                    src={product?.images[currentImageIndex].src}
                    alt="..."
                    id="productImage"
                    className="object-cover object-center"
                />
            </div>

            {/* <!-- Slider indicators --> */}
            <div className=" flex absolute bottom-5 left-1/2 z-30 space-x-3 -translate-x-1/2">
                {
                    product.images.map((image, index) => (
                        <button
                            key={index}
                            type="button"
                            className={
                                image.src === product?.images[currentImageIndex].src
                                    ? "w-3 h-3 rounded-full bg-white "
                                    : "w-3 h-3 rounded-full bg-gray-400 "
                            }
                            onClick={() => handleSetImage(index)}
                        ></button>
                    ))
                }
            </div>

            {/* <!-- Slider controls --> */}
            <button
                type="button"
                className="flex absolute top-0 z-0 left-0 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
                onClick={handlePrevImage}>
                <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                </span>
            </button>
            <button
                type="button"
                className="flex absolute top-0 z-0 right-0 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
                onClick={handleNextImage}>
                <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                </span>
            </button>
        </div>
    );
}

