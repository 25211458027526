import { createSlice } from "@reduxjs/toolkit";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";

interface InitialInterface {
    initProductsCar: {
        businessName: string;
        initProductsCar: ProductCartInterface[];
    }[];
    initModalShowed: boolean;
}

const initialState: InitialInterface = {
    initProductsCar: [],
    initModalShowed: false
};

const InitSlice = createSlice({
    initialState,
    name: "initSclice",
    reducers: {
        setInitProductsCar: (state, action) => ({
            ...state,
            initProductsCar: action.payload
        }),
        setInitModalShowed: (state, action) => ({
            ...state,
            initModalShowed: action.payload
        }),
    },
});

export const { setInitProductsCar, setInitModalShowed } = InitSlice.actions;

export default InitSlice.reducer;
