import { Fragment, useMemo, useState, createContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faHeart, faTags, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OriginalInfo, ProductCtx, ProductInterface, VariationsProd } from "../../../interfaces/ServerInterfaces";
import imageDefault from "../../../assets/jpg/no-imagen-shop.jpg"
import { getColorShowRemainQuantities, getShowRemainQuantities, maximumFlavorsToSelect, printPriceWithCommasAndPeriods } from "../../../utils/functions";
import { CarrouselProductImages } from "../../CarrouselProductImages";
import ProductVariations from "./ProductVariations";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { FreeAddons, FreeIceCreamAddons, productsForCakeCustomization, MeetsWithCoccionTerms, productsWithFlavorsId, productsWithFreeAddons, EggsWithCoccionTerms, MeetsCoccionTerms, EggsCoccionTerms, FreeIceCreamAddonsPlus, BreakfastAddOnData, ProductWithSauce } from "../../../utils/staticData";
import SelectFlavor from "../../SelectFlavor";
import SelectAddon from "../../SelectAddon";
import CakeCustomization from "../../CakeCustomization";
import SelectCoccionTerm from "../../SelectCoccionTerm";
import SelectIfIsWithSauceOrNot from "../../SelectIfIsWithSauceOrNot";

interface Props {
  onClick: Function;
  product: ProductInterface | null;
  addCart: Function;
  includeShop: boolean;

  cakeCustomizationData: any;
  volcanicCandelData: any;
  ceramicCandelData: any;
  when_shop_create_preorder: boolean;
  enable_to_sale_in_negative: boolean;

}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const productInfoContex: Partial<ProductCtx> = {};

export const ProductInfoContex = createContext(productInfoContex);


export default function ModalProduct({ onClick, product, addCart, includeShop, cakeCustomizationData, volcanicCandelData,
  ceramicCandelData, when_shop_create_preorder, enable_to_sale_in_negative }: Props) {

  // console.log({ product })
  // console.log(product?.id)

  const { currentCurrency } = useAppSelector((state: RootState) => state.session)

  const [currentPrice, setCurrentPrice] = useState(product?.onSalePrice !== null ? product?.onSalePrice?.amount : product?.prices[0].price);
  const [currentCodeCurrency, setCurrentCodeCurrency] = useState(product?.onSalePrice !== null ? product?.onSalePrice?.codeCurrency : product?.prices[0].codeCurrency);
  const [currentDescription, setCurrentDescription] = useState(product?.description);
  const [resultVariation, setResultVariation] = useState<VariationsProd | null>(null);
  // Addons  
  const isProductWithAddons = product?.availableAddons.length! > 0
  const [selectedAddons, setSelectedAddons] = useState<
    {
      addonName: string,
      quantity: number,
      prices: {
        price: number;
        codeCurrency: string;
        isMain: boolean;
      }[]
    }[]>([])
  const [totalAddons, setTotalAddons] = useState(0)
  const [addAddons, setAddAddons] = useState(false)
  const [withoutSauce, setWithoutSauce] = useState(false)

  //FreeAddons
  const isProductWithFreeAddons = productsWithFreeAddons.some(element => product?.id === element)

  // Comentado porque los agregos gratuitos no apareceran ya en la categoria desayuno
  // const isProductWithFreeAddons = productsWithFreeAddons.some(element => product?.id === element) || product?.salesCategory.name === "Desayunos"

  // Cake Customization
  const isProductsWithCakeCustomization = productsForCakeCustomization.some(element => product?.id === element)
    || product?.salesCategory?.name === "Tartas"
    || product?.salesCategory?.name === "Tartas heladas"
    || product?.salesCategory?.name === "Tartas formato grande"
  const [addPersonalization, setAddPersonalization] = useState(false)
  const [cakeCustomizationText, setCakeCustomizationText] = useState<string | null>(null)
  const [addCakeCustomization, setAddCakeCustomization] = useState(false)
  const [addVolcanicCandel, setAddVolcanicCandel] = useState(false)
  const [addCeramicCandel, setAddCeramicCandel] = useState(false)

  //Sause

  const isProductsWithSauce = ProductWithSauce.some(element => product?.id === element)
  // Coccion Terms
  const isProductsWithCoccionTerms = MeetsWithCoccionTerms.some(element => product?.id === element) || EggsWithCoccionTerms.some(element => product?.id === element)
  const [CoccionTerms, setCoccionTerms] = useState<string | null>(null)

  //Flavors
  const isProductWithFlavor = productsWithFlavorsId.some(id => id === product?.id)
  const [selectedFlavors, setSelectedFlavors] = useState<{ flavorName: string, quantity: number }[]>([])
  const maximumFlavors = maximumFlavorsToSelect(product?.id!)
  const [totalFlavors, setTotalFlavors] = useState(0)

  const initialPrice = useMemo(() => {
    return product?.onSalePrice !== null ? product?.onSalePrice?.amount : product?.prices[0].price;
  }, [product]);

  const initialCodeCurrency = useMemo(() => {
    return product?.onSalePrice !== null ? product?.onSalePrice?.codeCurrency : product?.prices[0].codeCurrency;
  }, [product]);

  const initialDescription = useMemo(() => {
    return product?.description;
  }, [product]);


  const originalInfo: OriginalInfo = {
    price: initialPrice,
    codeCurrency: initialCodeCurrency,
    description: initialDescription
  }

  const handleButtonClick = () => {
    if (product?.stockLimit === false || (product?.stockLimit && product?.totalQuantity > 0) || when_shop_create_preorder || enable_to_sale_in_negative) {
      //Si se encuentra en la lista de productos con sabores
      if (isProductWithFlavor) {
        //Si todos los sabores han sido añadidos
        if (totalFlavors === maximumFlavors) {
          onClick()
          if (addAddons || selectedFlavors.length > 0) {
            addCart({
              ...product,
              prices: [
                {
                  price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                  codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                }
              ],
              observations: `
              Sabores: ${selectedFlavors.map(flavors => {
                return `${flavors.quantity > 1 ? (flavors.quantity + (product?.name.includes("Yogurt") ? "" : " bolas")) : (flavors.quantity + (product?.name.includes("Yogurt") ? "" : " bola"))}
               ${(product?.name.includes("Yogurt") ? "" : " de")} 
               ${flavors.flavorName}`
              }).join(", ")}
              . Agregos: ${selectedAddons.filter(addon => addon.prices.length > 0
                ? addon?.prices?.find(add => add.codeCurrency === currentCodeCurrency)?.price === 0
                : addon
              ).map(addon => { return addon.addonName }).join(", ")}`
            })
          } else {
            addCart({
              ...product,
              prices: [
                {
                  price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                  codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                }
              ],
              observations: `Sabores: ${selectedFlavors.map(flavors => { return `${flavors.quantity > 1 ? (flavors.quantity + (product?.name.includes("Yogurt") ? "" : " bolas")) : (flavors.quantity + (product?.name.includes("Yogurt") ? "" : " bola"))} ${(product?.name.includes("Yogurt") ? "" : " de")} ${flavors.flavorName}` }).join(", ")}`
            })
          }

        }
      } else {
        //Es de tipo variable
        if (product?.type === "VARIATION") {
          if (resultVariation !== null) {
            onClick()
            addCart(
              {
                ...product,
                id: product?.id,
                variationId: resultVariation.id,
                name: product?.name + " " + resultVariation?.name,
                prices: [
                  {
                    price: resultVariation.price ? resultVariation.price.amount : originalInfo.price,
                    codeCurrency: resultVariation.price ? resultVariation.price.codeCurrency : originalInfo.codeCurrency
                  }
                ],
                totalQuantity: resultVariation.stockAreaVariations[0].quantity
              }
            )

          }
        } else {
          onClick()
          //Tiene agregos que agregar
          if (addAddons || selectedAddons.length > 0) {
            addCart({
              ...product,
              prices: [
                {
                  price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                  codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency,
                }
              ],
              addons: selectedAddons.filter(addon => addon?.prices?.find(add => add.codeCurrency === currentCodeCurrency)?.price! > 0).map(addon => (
                {
                  id: product?.availableAddons?.find(element => element?.name === addon.addonName)?.id,
                  quantity: addon.quantity,
                  prices: addon.prices
                }
              )),
              observations: `
              ${selectedAddons.filter(addon => addon?.prices.length === 0 && (addon.addonName === "Mostaza" || addon.addonName === "Ketchup" || addon.addonName === "Mayonesa")).length > 0
                  ? `Agregos libre de costo: ${selectedAddons.filter(addon => addon?.prices.length === 0 && (addon.addonName === "Mostaza" || addon.addonName === "Ketchup" || addon.addonName === "Mayonesa")).map(addon => { return addon.addonName }).join(", ") + "."}`
                  : ""
                } 

              ${selectedAddons.filter(addon => addon?.prices.length === 0 && (addon.addonName !== "Mostaza" && addon.addonName !== "Ketchup" && addon.addonName !== "Mayonesa")).length > 0
                  ? `Producto libre de costo: ${selectedAddons.filter(addon => addon?.prices.length === 0 && (addon.addonName !== "Mostaza" && addon.addonName !== "Ketchup" && addon.addonName !== "Mayonesa")).map(addon => { return addon.addonName }).join(", ") + "."}`
                  : ""
                } 
                  
                ${selectedAddons.filter(addon => addon?.prices.length > 0).length > 0
                  ? ` Agregos: ${selectedAddons.filter(addon => addon?.prices?.find(add => add.codeCurrency === currentCodeCurrency)?.price! > 0).map(addon => { return addon.quantity + " " + addon.addonName }).join(", ")}`
                  : ""
                }

                ${withoutSauce !== false ? "Sin Salsa" : ""}
                  `
            })
            //Producto convencional normal
          } else {
            //Si es de tipo Tarta, se debe agregar la personalizacion (si la lleva) en las observaciones
            if (addPersonalization) {
              addCart({
                ...product,
                prices: [
                  {
                    price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                    codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                  }
                ],
                observations: `${cakeCustomizationText !== "" ? `Personalización: ${cakeCustomizationText}` : ""}`
              },
                //Activando que se agregue el producto personalizacion de tartas junto con este
                (cakeCustomizationText !== null && cakeCustomizationText !== ""),
                //Activando que se agregue el producto vela volcanica junto con este
                addVolcanicCandel,
                //Activando que se agregue el producto vela de cera junto con este
                addCeramicCandel,

              )
              //Si es de tipo Carne, se añade a las observaciones el punto de cocción
            } else if (CoccionTerms !== null) {
              addCart({
                ...product,
                prices: [
                  {
                    price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                    codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                  }
                ],
                observations: `
                Punto de cocción: ${CoccionTerms} 
                ${withoutSauce !== false ? "Sin Salsa" : ""}
                `
              })
            } else {
              addCart({
                ...product,
                prices: [
                  {
                    price: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.price,
                    codeCurrency: product?.prices.find(elem => elem.codeCurrency === currentCurrency)?.codeCurrency
                  }
                ],
                observations: `
                ${withoutSauce !== false ? "Sin Salsa" : ""}
                `
              })
            }

          }

        }
      }
    }
  }

  const getButtonText = () => {
    if (product?.stockLimit && product?.totalQuantity === 0 && !(when_shop_create_preorder || enable_to_sale_in_negative)) {
      return "Agotado"
    } else {
      if (product?.stockLimit && product?.totalQuantity > 0 && !(when_shop_create_preorder || enable_to_sale_in_negative)) {

        if (totalFlavors === maximumFlavors || !isProductWithFlavor) {
          return "Añadir a la bolsa"

        } else {
          return "Seleccione los sabores"
        }
      } else {
        if (product?.stockLimit && !(when_shop_create_preorder || enable_to_sale_in_negative)) {
          return "Agotado"
        } else {
          if (totalFlavors === maximumFlavors || !isProductWithFlavor) {
            return "Añadir a la bolsa"
          } else {
            return "Seleccione los sabores"
          }
        }
      }
    }
  }

  return (
    <ProductInfoContex.Provider
      value={
        {
          currentPrice,
          setCurrentPrice,
          currentDescription,
          setCurrentDescription,
          currentCodeCurrency,
          setCurrentCodeCurrency,
          originalInfo,
          setResultVariation,
          resultVariation,
          selectedFlavors,
          setSelectedFlavors,
          maximumFlavors,
          setTotalFlavors,
          totalFlavors,
          selectedAddons,
          setSelectedAddons,
          totalAddons,
          setTotalAddons,
          addAddons,
          setAddAddons,
          cakeCustomizationText,
          setCakeCustomizationText,
          CoccionTerms,
          setCoccionTerms,
          addCakeCustomization,
          setAddCakeCustomization,
          addVolcanicCandel,
          setAddVolcanicCandel,
          addCeramicCandel,
          setAddCeramicCandel,
          addPersonalization,
          setAddPersonalization,
          withoutSauce,
          setWithoutSauce,
        }
      }>
      <Transition.Root show={true} as={Fragment} key={product?.id}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => onClick()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-60"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-20"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="p-4 flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="rounded-md relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                      onClick={() => onClick()}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>

                    <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                      <div className="sm:col-span-4 lg:col-span-5 self-center justify-center items-center">
                        {/* <div className="sm:col-span-4 lg:col-span-5 self-center justify-center items-center"> */}
                        <div className="overflow-hidden rounded-lg bg-gray-100">

                          {/* Determines when to display an image carousel, a single image, and the default image */}
                          {
                            product?.images
                              .length !==
                              undefined &&
                              product?.images.length >
                              1
                              ? <CarrouselProductImages product={product} />
                              : (<img
                                src={
                                  product?.images
                                    .length !==
                                    undefined &&
                                    product?.images.length ===
                                    1
                                    ? product?.images[0]
                                      .src
                                    : imageDefault
                                }
                                alt={product?.name}
                                className="object-cover object-center"
                              />)
                          }
                        </div>
                      </div>

                      <div className="sm:col-span-8 lg:col-span-7">
                        <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                          {product?.name}
                        </h2>

                        <section
                          aria-labelledby="information-heading"
                          className="mt-3"
                        >
                          <div className="flex flex-row">
                            {product?.onSale && (
                              <p
                                className={`text-2xl mr-2 text-gray-900`}
                              >
                                {printPriceWithCommasAndPeriods(currentPrice!) +
                                  " " +
                                  currentCodeCurrency}
                              </p>
                            )}
                            <p
                              className={` ${product?.onSale
                                ? "line-through text-slate-500 text-lg mt-1"
                                : "text-gray-900 text-2xl"
                                }`}
                            >
                              {
                                Intl.NumberFormat("en-IN").format(
                                  (resultVariation === null
                                    ? product?.prices.find(element => element.codeCurrency === currentCurrency)?.price
                                    : currentPrice) || 0)
                                +
                                (Number.isInteger(resultVariation === null
                                  ? product?.prices.find(element => element.codeCurrency === currentCurrency)?.price
                                  : currentPrice) ? ".00 " : " ")
                                + (resultVariation === null
                                  ? currentCurrency
                                  : currentCodeCurrency)

                              }
                            </p>

                          </div>

                          <div className="mt-6">
                            {product?.showRemainQuantities &&
                              <p className=
                                {classNames(
                                  `${getColorShowRemainQuantities(product?.totalQuantity)}`
                                  ,
                                  "text-xs font-medium truncate h-5 -mt-4"
                                )}
                              >
                                {" "}

                                {!(when_shop_create_preorder || enable_to_sale_in_negative) && getShowRemainQuantities(product?.totalQuantity)

                                }
                              </p>}
                            <h4 className="sr-only">
                              Description
                            </h4>

                            <p className="text-sm text-gray-700">
                              {currentDescription}
                              <span className="text-red-500">{product?.name === "Cono Vacío" && "Solo en tienda fisica"}</span>
                            </p>

                          </div>
                        </section>

                        <section
                          aria-labelledby="options-heading"
                          className="mt-6"
                        >
                          <form>

                            {
                              product?.type === "VARIATION"
                              && (
                                <ProductVariations variations={product?.variations} stockAreaProducts={product.stockAreaProducts} />
                              )
                            }
                            {
                              isProductWithFlavor && (
                                <SelectFlavor flavorType={product?.name.includes("Yogurt") ? "Yogurt" : "IceCream"} />
                                // <SelectFlavor flavorType={product?.name.includes("Yogurt") ? "Yogurt" : "IceCream"} />
                              )
                            }

                            {
                              (isProductWithFreeAddons) && (
                                <SelectAddon addons={product?.availableAddons!} freeAddons={
                                  //Tiene en cuenta si el producto pertenece a "Helados" para cambiar el tipo de agrego
                                  productsWithFlavorsId.some(element => element === productsWithFreeAddons.find(element => product?.id === element))
                                    ? product?.name.toLowerCase().includes("batido")
                                      ? FreeIceCreamAddonsPlus
                                      : FreeIceCreamAddons
                                    : FreeAddons
                                }
                                  isFreeAddons={isProductWithFreeAddons}
                                  isPaymentAddon={isProductWithAddons}
                                />
                              )
                            }

                            {/* Agregos libre de costos para todos los productos en categoria */}

                            {
                              (product?.salesCategory?.name === "Desayunos") && (
                                <SelectAddon
                                  addons={product?.availableAddons!}
                                  freeAddons={BreakfastAddOnData}
                                  isFreeAddons={true}
                                  // isFreeAddons={isProductWithFreeAddons}
                                  isPaymentAddon={isProductWithAddons}
                                  isBreakfastAddon
                                />
                              )
                            }

                            {
                              isProductsWithCakeCustomization && (
                                <CakeCustomization
                                  cakeCustomizationData={cakeCustomizationData}
                                  volcanicCandelData={volcanicCandelData}
                                  ceramicCandelData={ceramicCandelData}
                                />
                              )
                            }

                            {
                              isProductsWithCoccionTerms && (
                                <SelectCoccionTerm addons={
                                  //Tiene en cuenta si el producto pertenece a "Carnes" o "Huevos" para cambiar el tipo de coccion
                                  MeetsWithCoccionTerms.some(element => element === product?.id)
                                    ? MeetsCoccionTerms
                                    : EggsCoccionTerms
                                } />
                              )
                            }

                            {
                              isProductsWithSauce && (
                                <SelectIfIsWithSauceOrNot
                                />
                              )
                            }

                            <div className="mt-6">

                              {includeShop &&
                                <>
                                  <button
                                    type="button"
                                    className={
                                      product?.stockLimit && product?.totalQuantity === 0 && !(when_shop_create_preorder || enable_to_sale_in_negative)
                                        ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                                        : product?.stockLimit && product?.totalQuantity > 0 && !(when_shop_create_preorder || enable_to_sale_in_negative)
                                          ? `${(product?.type === "VARIATION" && resultVariation === null) ? "bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500" : "bg-pink-500 text-white hover:bg-pink-700 focus:ring-pink-500"} w-full overflow-hidden truncate inline-flex  items-center justify-center text-center rounded-md border border-transparent py-2 text-sm font-medium  focus:outline-none focus:ring-2  focus:ring-offset-2`
                                          : product?.stockLimit && !(when_shop_create_preorder || enable_to_sale_in_negative)
                                            ? "w-full overflow-hidden truncate inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-red-600  py-2 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-red-900 focus:ring-offset-2"
                                            : `${(product?.type === "VARIATION" && resultVariation === null) ? "bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500" : "bg-pink-500 text-white hover:bg-pink-700 focus:ring-pink-500"} w-full overflow-hidden truncate inline-flex  items-center justify-center text-center rounded-md border border-transparent py-2 text-sm font-medium  focus:outline-none focus:ring-2  focus:ring-offset-2`
                                    }
                                    onClick={handleButtonClick}
                                  >
                                    {product?.onSale && (
                                      <FontAwesomeIcon
                                        icon={faTags}
                                        className=" mx-1"
                                        size="sm"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {product?.suggested && (
                                      <FontAwesomeIcon
                                        icon={faHeart}
                                        className=" mx-1"
                                        size="sm"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {getButtonText()}
                                  </button>

                                </>}
                            </div>
                          </form>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </ProductInfoContex.Provider>
  );
}