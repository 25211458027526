/* eslint-disable array-callback-return */
import {
} from "@fortawesome/free-solid-svg-icons";
import { memo } from "react";
import { ProductInterface } from "../../interfaces/ServerInterfaces";
import imageDefault from "../../assets/jpg/no-imagen-shop.jpg"

interface Props {
  multipleProduct: {
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  };
  onClick: Function;
  priceSystemId: number;
  cardWidth: string;
  addCart: Function;
  includeShop: boolean
}

const CardMultiProduct = ({
  multipleProduct,
  onClick,
  priceSystemId,
  cardWidth,
  addCart,
  includeShop
}: Props) => {

  const getButtonText = () => {
    return "Ver productos"
  };

  return (
    <>
      <div
        className={`h-96 ${cardWidth}  rounded-lg scrollbar-hide  overflow-hidden   shadow-md inline-block  cursor-pointer  ease-in-out duration-300`}
      >
        <div className="flex h-52 w-full   bg-slate-200 group-hover:opacity-75 ">
          <img
            src={
              multipleProduct.repeatedProducts[0]?.images
                .length !==
                undefined &&
                multipleProduct.repeatedProducts[0]?.images.length > 0
                ? multipleProduct.repeatedProducts[0]?.images[0]
                  .src
                : imageDefault
            }
            alt={multipleProduct.repeatedProducts[0]?.name}
            className="h-full max-h-52 w-full object-cover object-center"
            onClick={() => onClick(multipleProduct)}
          />
        </div>

        <div className="flex flex-1 w-full whitespace-nowrap  flex-col justify-end space-y-1 p-2">
          <h3
            className="text-lg truncate font-medium text-slate-900"
            onClick={() => onClick(multipleProduct)}
          >
            {multipleProduct.repeatedWord === "S"
              ? "S - Hamburguesa"
              : multipleProduct.repeatedWord === "C"
                ? "C - Hamburguesa"
                : multipleProduct.repeatedWord}
          </h3>
          <p className="text-sm truncate h-5 -mt-2 text-slate-500">
            {" "}
          </p>

          <h5
            className="text-base  font-medium  text-white">
            A
          </h5>

          {
            !includeShop ?
              <button
                type="button"
                onClick={() => {
                  onClick(multipleProduct)
                }}
                className=" w-full overflow-hidden truncate inline-flex items-center justify-center text-center rounded-md border border-transparent bg-cyan-100  py-2 text-sm font-medium text-cyan-700 hover:bg-cyan-200 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                Ver más
              </button>
              :
              <>
                <button
                  type="button"
                  className=" w-full overflow-hidden truncate   inline-flex  items-center   justify-center text-center rounded-md border border-transparent bg-pink-500  py-2 text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                  onClick={() => { onClick(multipleProduct) }}
                >
                  {getButtonText()}
                </button>
              </>
          }
        </div>
      </div>
    </>
  );
}

export default memo(CardMultiProduct)
