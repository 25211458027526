/* eslint-disable react-hooks/exhaustive-deps */
import { IceCreamflavors, Yogurtflavors } from '../utils/staticData';
import { useContext, useEffect, useState } from 'react';
import {
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductInfoContex } from './modals/modalProduct/ModalProduct';

const SelectFlavor = ({ flavorType }: { flavorType: string }) => {

  const [correspondingFlavour, setCorrespondingFlavour] = useState<{ name: string; color: string; }[]>([])

  useEffect(() => {
    if (flavorType === "Yogurt") {
      setCorrespondingFlavour(Yogurtflavors)
    }
    if (flavorType === "IceCream") {
      setCorrespondingFlavour(IceCreamflavors)
    }
  }, [])


  const {
    selectedFlavors,
    setSelectedFlavors,
    maximumFlavors,
    setTotalFlavors,
    totalFlavors
  } = useContext(ProductInfoContex);

  const onAddFlavor = (flavor: { name: any; color?: string; }) => {

    const existentFlavor = selectedFlavors?.find(slctFlavor => slctFlavor.flavorName === flavor.name)

    if (existentFlavor) {
      setSelectedFlavors(selectedFlavors?.map(slctdFlavor => {
        if (existentFlavor.flavorName === slctdFlavor.flavorName) {
          return {
            flavorName: slctdFlavor.flavorName,
            quantity: slctdFlavor.quantity + 1
          }
        } else {
          return {
            flavorName: slctdFlavor.flavorName,
            quantity: slctdFlavor.quantity
          }
        }
      }))
    } else {
      setSelectedFlavors([...selectedFlavors!, { flavorName: flavor.name, quantity: 1 }])
    }

  }

  const onRestFlavor = (flavor: { name: any; color?: string; }) => {

    const existentFlavor = selectedFlavors?.find(slctFlavor => slctFlavor.flavorName === flavor.name)

    if (existentFlavor?.quantity! > 1) {
      setSelectedFlavors(selectedFlavors?.map(slctdFlavor => {
        if (existentFlavor?.flavorName === slctdFlavor.flavorName) {
          return {
            flavorName: slctdFlavor.flavorName,
            quantity: slctdFlavor.quantity - 1
          }
        } else {
          return {
            flavorName: slctdFlavor.flavorName,
            quantity: slctdFlavor.quantity
          }
        }
      }))
    } else {
      setSelectedFlavors(selectedFlavors?.filter(selectedFlavor => flavor.name !== selectedFlavor.flavorName))
    }
  }

  useEffect(() => {

    let aux = 0

    selectedFlavors?.forEach(flavor => {
      aux = aux + flavor.quantity
    })

    setTotalFlavors(aux)

  }, [selectedFlavors])


  return (
    <div>
      <div className=' flex justify-between items-center'>
        <h2 className='font-bold text-xl'>Elige {maximumFlavors} {maximumFlavors! > 1 ? "sabores" : "sabor"} para tu {flavorType === "Yogurt" ? "yogurt" : flavorType === "IceCream" && "helado"}</h2>
        {/* <p className='text-white bg-black p-2 rounded-xl font-bold uppercase  '>Obligatorio</p> */}
      </div>
      <p className={`font-thin text-xl pl-4 ${totalFlavors! > 0 ? "text-black" : "text-white"}`}>Sabores seleccionados: {totalFlavors}</p>
      <div className={`${flavorType === "IceCream" ? "grid grid-rows-6 grid-cols-4 gap-x-60 mt-5" : "flex flex-col mt-2"}   overflow-x-scroll items-center`}>

        {correspondingFlavour.map((flavor) => (
          <div className="flex justify-between w-56 min-h-10 h-min-10 h-auto my-1 items-center bg-gradient-to-r from-slate-200 to-slate-100 rounded-md">
            <div className='flex items-center'>
              <div className={`h-5 w-5 bg-${flavor.name} rounded-full mr-4`}></div>
              <p className={`capitalize text-xl my-1`}>{flavor.name}</p>
            </div>

            <div className='flex items-center w-auto justify-between'>
              {/* MinusIcon */}
              {
                selectedFlavors?.some(slctFlavor => slctFlavor.flavorName === flavor.name) && (
                  <FontAwesomeIcon
                    icon={
                      faMinusCircle
                    }
                    className=" mx-2 text-gray-500 hover:text-cyan-500"
                    size="xl"
                    aria-hidden="true"
                    onClick={() => {
                      onRestFlavor(flavor)
                    }}
                  />
                )
              }

              {/* QuantitySelected */}
              {
                selectedFlavors?.some(selectedFlavor => selectedFlavor.flavorName === flavor.name) && (
                  <p className='font-semibold'>{selectedFlavors?.find(selectedFlavor => selectedFlavor.flavorName === flavor.name)?.quantity}</p>
                )
              }

              {/* PlusIcon */}

              <FontAwesomeIcon
                icon={
                  faPlusCircle
                }
                className={`mx-2 
                    ${totalFlavors !== maximumFlavors
                    ? "text-gray-500 hover:text-cyan-500"
                    : "text-gray-300 hover:text-gray-200"
                  } `}
                size="xl"
                aria-hidden="true"
                onClick={() => {
                  if (totalFlavors !== maximumFlavors) {
                    onAddFlavor(flavor)
                  }
                }}
              />


            </div>
          </div>
        )

        )}

      </div>
    </div>
  )
}

export default SelectFlavor
