import './index.css';
import './vars.css';
import 'tailwindcss/tailwind.css';

import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';
import { persistor } from './store/store';
import reportWebVitals from './reportWebVitals';
import { MainRoute } from './routes/MainRoute';
import { Providers } from './store/Providers';
import Loading from './components/Loading';
import { PersistGate } from 'redux-persist/integration/react'

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Providers>
    <PersistGate persistor={persistor} loading={<Loading />}>
      <MainRoute />
    </PersistGate>
  </Providers>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
