import LoadingSpin from "./misc/LoadingSpin";

export default function Loading() {
  return (
    <div className='flex flex-col  h-screen w-screen justify-center items-center '>
      <img
        className="h-24 w-auto animate-pulse "
        src={require("../assets/png/El-Gelato-Logo-Colores-1.png")}
        alt=""
      />
      <LoadingSpin color="cyan-600" />
    </div>
  )
}
