import { Provider } from "react-redux"
import { store } from "./store"
import { injectStore } from "../services/APIServer"
import { injectMediaStore } from "../services/APIMediaServer"

interface Props { children: React.ReactNode }

injectStore(store)
injectMediaStore(store)

export function Providers({ children }: Props) {
    return <Provider store={store}>
        {children}
    </Provider>
}
