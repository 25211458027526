import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setRegions, setPaymentWay } from "../store/slices/FormSlice";
import axios from "axios";

export const useServerForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const { key, businessId } = useAppSelector((state) => state.session);

  const findAllRegions = async () => {
    setIsLoading(true);
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/shop/shipping/region?all_data=true`,
        {
          headers: {
            "X-App-Origin": "Tecopos-Shop",
            "X-App-Authorization": `Bearer ${process.env.REACT_APP_ACCESSKEY}`,
            "X-App-BusinessId": businessId,
            Authorization: `Bearer ${key?.token}`,
          },
        }
      );
      // setData(response.data.items);
      dispatch(setRegions(response.data.items));
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };

  const getPaymentWays = async () => {
    setIsLoading(true);
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_VERSION_API}/shop/paymentgateway`,
        {
          headers: {
            "X-App-Origin": "Tecopos-Shop",
            "X-App-Authorization": `Bearer ${process.env.REACT_APP_ACCESSKEY}`,
            "X-App-BusinessId": businessId,
            Authorization: `Bearer ${key?.token}`,
          },
        }
      );
      // setData(response.data.items);
      dispatch(setPaymentWay(response.data));
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setError(error);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    findAllRegions,
    getPaymentWays,
  };
};
