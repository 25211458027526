import { createSlice } from "@reduxjs/toolkit";
import { PaymentInterface, RegionsInterface } from "../../interfaces/ServerInterfaces";

interface InitialInterface {
  regions: (RegionsInterface | null)[];
  paymentWay: (PaymentInterface | null)[];
  tropiPayURL: string | null;
}

const initialState: InitialInterface = {
  regions: [],
  paymentWay: [],
  tropiPayURL: null
};

const formSlice = createSlice({
  initialState,
  name: "form",
  reducers: {
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload
    }),
    setPaymentWay: (state, action) => ({
      ...state,
      paymentWay: action.payload
    }),
    setTropiPayURL: (state, action) => ({
      ...state,
      tropiPayURL: action.payload
    }),
  },
});

export const { setRegions, setPaymentWay, setTropiPayURL } = formSlice.actions;

export default formSlice.reducer;
