/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import {
  faPlusCircle,
  faMinusCircle,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductInfoContex } from './modals/modalProduct/ModalProduct';
import { AvailableAddonInterface } from '../interfaces/ServerInterfaces';
import GenericToggle from './misc/GenericToggle';
import { useAppSelector } from '../store/hooks';
import { printPriceWithCommasAndPeriods } from '../utils/functions';


const SelectAddon = ({ addons, freeAddons, isFreeAddons, isPaymentAddon, isBreakfastAddon }: { addons: AvailableAddonInterface[], freeAddons?: AvailableAddonInterface[], isFreeAddons?: boolean, isPaymentAddon?: boolean, isBreakfastAddon?: boolean, }) => {

  const { currentCurrency } = useAppSelector(state => state.session)

  const {
    selectedAddons,
    setSelectedAddons,
    setTotalAddons,
    totalAddons,
    addAddons,
    setAddAddons
  } = useContext(ProductInfoContex);


  const onAddAddon = (addon: AvailableAddonInterface) => {
    const existentAddon = selectedAddons?.find(slctAddon => slctAddon.addonName === addon.name)

    if (existentAddon) {
      setSelectedAddons(selectedAddons?.map(slctdAddon => {
        if (existentAddon.addonName === slctdAddon.addonName) {
          return {
            addonName: slctdAddon.addonName,
            quantity: slctdAddon.quantity + 1,
            prices: slctdAddon.prices
          }
        } else {
          return {
            addonName: slctdAddon.addonName,
            quantity: slctdAddon.quantity,
            prices: slctdAddon.prices
          }
        }
      }))
    } else {
      setSelectedAddons([...selectedAddons!, {
        addonName: addon.name,
        quantity: 1,
        prices: addon?.prices!
      }])
    }
  }

  const onRestAddon = (addon: { name: any; color?: string; }) => {

    const existentAddon = selectedAddons?.find(slctAddon => slctAddon.addonName === addon.name)

    if (existentAddon?.quantity! > 1) {
      setSelectedAddons(selectedAddons?.map(slctdAddon => {
        if (existentAddon?.addonName === slctdAddon.addonName) {
          return {
            ...slctdAddon,
            quantity: slctdAddon.quantity - 1
          }
        } else {
          return {
            ...slctdAddon,
          }
        }
      }))
    } else {
      setSelectedAddons(selectedAddons?.filter(selectedAddon => addon.name !== selectedAddon.addonName))
    }
  }

  useEffect(() => {

    let aux = 0

    selectedAddons?.forEach(addon => {
      aux = aux + addon.quantity
    })

    setTotalAddons(aux)

  }, [selectedAddons])

  return (
    <div>
      {
        isFreeAddons && (
          <div>
            {
              isFreeAddons && (
                <h2 className='font-lg text-green-500 text-md mt-2'>{isBreakfastAddon ? "*Seleccione libre de costo:" : "*Agregue libre de costo:"}</h2>
              )
            }
          </div>
        )
      }
      {
        isFreeAddons && freeAddons?.map((addon) => (
          <div
            className="flex cursor-pointer hover:opacity-60 justify-between w-full min-h-10 h-min-10 h-auto my-1 items-center bg-gradient-to-r from-slate-200 to-slate-100 rounded-md"
            onClick={() => {

              if (!isBreakfastAddon || isBreakfastAddon === undefined) {
                if (selectedAddons?.some(element => element.addonName === addon.name)) {
                  setSelectedAddons(selectedAddons.filter(element => element.addonName !== addon.name))
                } else {
                  setSelectedAddons([...selectedAddons!,
                  {
                    addonName: addon.name,
                    quantity: 1,
                    prices: addon?.prices! ?? [
                      // {
                      //   price: 0,
                      //   codeCurrency: currentCurrency,
                      //   isMain: false
                      // }
                    ]
                  }])
                }
              } else {
                setSelectedAddons([...selectedAddons?.filter(addon => addon.addonName === "Mostaza" || addon.addonName === "Ketchup" || addon.addonName === "Mayonesa")!,
                {
                  addonName: addon.name,
                  quantity: 1,
                  prices: addon?.prices! ?? [
                    // {
                    //   price: 0,
                    //   codeCurrency: currentCurrency,
                    //   isMain: false
                    // }
                  ]
                }])
              }


            }}
          >
            <div className='flex items-center justify-between w-full px-10'>
              {/* <div className={`h-5 w-5 bg-${addon.name} rounded-full mr-4`}></div> */}
              <p className={`capitalize text-xl my-1`}>{addon.name}</p>

              {
                selectedAddons?.some(element => element.addonName === addon.name) && (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        faCheck
                      }
                      className="mx-2 text-cyan-500 w-full"
                      size="xl"
                      aria-hidden="true"
                    />
                  </div>
                )
              }

            </div>
          </div>
        ))
      }

      {
        (isPaymentAddon) && (
          <GenericToggle
            title='Adicionar agregos'
            currentState={addAddons!}
            changeState={setAddAddons!}
          />
        )
      }

      {
        (addAddons) && (
          <>
            <div className=' flex justify-between items-center'>
              <h2 className='font-bold text-xl mt-2'>
                Elige los agregos para tu producto
              </h2>
              {/* <p className='text-white bg-black p-2 rounded-xl font-bold uppercase  '>Obligatorio</p> */}
            </div>

            <p className={`font-thin text-xl pl-4 ${totalAddons! > 0 ? "text-black" : "text-white"}`}>Agregos seleccionados: {totalAddons}</p>
            <div className={`flex flex-col items-center`}>

              {addons.map((addon) => (
                <div className="flex justify-between w-full min-h-10 h-min-10 h-auto my-1 items-center bg-gradient-to-r from-slate-200 to-slate-100 rounded-md">
                  <div className='flex items-center'>
                    <div className={`h-5 w-5 bg-${addon.name} rounded-full mr-4`}></div>
                    <p className={`capitalize text-xl my-1`}>{addon.name}</p>
                    {
                      addon.prices && (
                        <p className={`capitalize text-md font-thin my-1 text-end ml-3`}>{printPriceWithCommasAndPeriods(addon.prices?.find(price => price.codeCurrency === currentCurrency)?.price!) + " " + currentCurrency}</p>
                      )
                    }
                  </div>

                  <div className='flex items-center w-auto justify-between'>
                    {/* MinusIcon */}
                    {
                      selectedAddons?.some(slctAddon => slctAddon.addonName === addon.name) && (
                        <FontAwesomeIcon
                          icon={
                            faMinusCircle
                          }
                          className=" mx-2 text-gray-500 hover:text-cyan-500"
                          size="xl"
                          aria-hidden="true"
                          onClick={() => {
                            onRestAddon(addon)
                          }}
                        />
                      )
                    }

                    {/* QuantitySelected */}
                    {
                      selectedAddons?.some(selectedAddon => selectedAddon.addonName === addon.name) && (
                        <p className='font-semibold'>{selectedAddons?.find(selectedAddon => selectedAddon.addonName === addon.name)?.quantity}</p>
                      )
                    }

                    {/* PlusIcon */}

                    <FontAwesomeIcon
                      icon={
                        faPlusCircle
                      }
                      className="mx-2 text-gray-500 hover:text-cyan-500"
                      size="xl"
                      aria-hidden="true"
                      onClick={() => {
                        onAddAddon(addon)
                      }}
                    />


                  </div>
                </div>
              )

              )}

            </div>
          </>
        )
      }

    </div>
  )
}

export default SelectAddon
