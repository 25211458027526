import { useField } from "formik";
import React from "react";

export const MyTextInput = ({ label, ...props }:any) => {
  const [field] = useField(props);

  return (
    <div className={`${props.inputclass}`}>
      <label
        htmlFor={props.id || props.name}
        className="block capitalize text-sm font-medium mt-2 text-gray-700"
      >
        {label}
      </label>
      <input
        className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        {...field}
        {...props}
      />
    </div>
  );
};
export default MyTextInput;
