import React, { useContext } from 'react'
import { ProductInfoContex } from './modals/modalProduct/ModalProduct';
import GenericToggle from './misc/GenericToggle';

const SelectIfIsWithSauceOrNot = () => {

  const {
    withoutSauce,
    setWithoutSauce
  } = useContext(ProductInfoContex);

  return (
    <div>
      <GenericToggle
        title='Sin salsa'
        currentState={withoutSauce!}
        changeState={setWithoutSauce!}
      />

    </div>
  )
}

export default SelectIfIsWithSauceOrNot
