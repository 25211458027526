/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { ProductInfoContex } from './modals/modalProduct/ModalProduct';
import GenericToggle from './misc/GenericToggle';
import TextArea from './forms/TextArea';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../store/hooks';
import { printPriceWithCommasAndPeriods } from '../utils/functions';


interface Props {
  cakeCustomizationData: any;
  volcanicCandelData: any;
  ceramicCandelData: any;
}

const CakeCustomization = ({ cakeCustomizationData,
  volcanicCandelData,
  ceramicCandelData
}: Props) => {

  const { currentCurrency } = useAppSelector(state => state.session)

  const {
    addPersonalization,
    setAddPersonalization,
    setCakeCustomizationText,
    addCakeCustomization,
    setAddCakeCustomization,
    addVolcanicCandel,
    setAddVolcanicCandel,
    addCeramicCandel,
    setAddCeramicCandel,
  } = useContext(ProductInfoContex);

  const { control, watch } = useForm()

  useEffect(() => {

    setCakeCustomizationText(watch("customerNotes") ?? "")

  }, [watch("customerNotes")])


  return (
    <div>
      <GenericToggle
        title='Adicionar personalización (precio adicional)'
        currentState={addPersonalization!}
        changeState={setAddPersonalization!}
      />

      {
        addPersonalization && (
          <div className='ml-4'>
            <div className='flex justify-between items-center'>
              <GenericToggle
                title="Adicionar texto de chocolate"
                currentState={addCakeCustomization!}
                changeState={setAddCakeCustomization!}
              />
              <p className='pr-4'>{printPriceWithCommasAndPeriods(cakeCustomizationData?.prices?.find((elem: { codeCurrency: string | null; }) => elem?.codeCurrency === currentCurrency).price!)} {cakeCustomizationData?.prices?.find((elem: { codeCurrency: string | null; }) => elem?.codeCurrency === currentCurrency).codeCurrency}</p>
            </div>

            {
              addCakeCustomization && (
                <>
                  <TextArea
                    name="customerNotes"
                    control={control}
                    label="Texto en la tarta"
                    maxDigits={30}
                  />
                  <p className="font-thin text-end">{watch("customerNotes")?.length ?? "0"}/30</p>
                </>
              )
            }

            <div className='flex justify-between items-center'>
              <GenericToggle
                title='Incluir vela volcánica'
                currentState={addVolcanicCandel!}
                changeState={setAddVolcanicCandel!}
              />
              <p className='pr-4'>{printPriceWithCommasAndPeriods(volcanicCandelData?.prices?.find((elem: { codeCurrency: string | null; }) => elem.codeCurrency === currentCurrency).price)} {cakeCustomizationData?.prices?.find((elem: { codeCurrency: string | null; }) => elem.codeCurrency === currentCurrency).codeCurrency}</p>
            </div>

            <div className='flex justify-between items-center'>
              <GenericToggle
                title='Incluir vela de cera'
                currentState={addCeramicCandel!}
                changeState={setAddCeramicCandel!}
              />

              <p className='pr-4'>{printPriceWithCommasAndPeriods(ceramicCandelData?.prices?.find((elem: { codeCurrency: string | null; }) => elem.codeCurrency === currentCurrency).price)} {cakeCustomizationData?.prices?.find((elem: { codeCurrency: string | null; }) => elem.codeCurrency === currentCurrency).codeCurrency}</p>
            </div>


          </div>
        )
      }


    </div>
  )
}

export default CakeCustomization
