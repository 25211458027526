// import useServer from '../../api/useServerMain';
import { useAppSelector } from '../../store/hooks';

import Input from '../../components/forms/Input'
import { useForm, SubmitHandler } from "react-hook-form";
import Select from '../../components/forms/Select';
import AsyncComboBox from '../../components/forms/AsyncCombobox';
import useServer from '../../services/useServerMain';

const EditProfile = () => {

    const { isFetching, editMyUserClient } = useServer();

    const { handleSubmit, control, watch, getValues } = useForm();

    const { user } = useAppSelector(state => state.session)

    const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
        values
    ) => {
        const {
            displayName,
            email,
            // firstName,
            // lastName,
            // sex,
            // street_1,
            // street_2,
            // birthAt,
            // number,
            // countryId,
            // provinceId,
            // municipalityId,
            // city
        } = values

        const data = JSON.stringify({
            displayName: displayName,
            email: email,
            // firstName: firstName,
            // lastName: lastName,
            // sex: sex,
            // birthAt: birthAt ? birthAt : " ",
            // notificationToken: "aaaa",
            // address: {
            //     street_1: street_1 ? street_1 : " ",
            //     street_2: street_2 ? street_2 : " ",
            //     city: city ? city : " ",
            //     municipalityId: municipalityId,
            //     provinceId: provinceId,
            //     countryId: countryId,
            // },
            // phones: [
            //     {
            //         number: number ? number : '',
            //         description: "Celular",
            //         isMain: true,
            //     },
            // ],
        });

        editMyUserClient(data)

    };

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Información personal</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Use una dirección permanente donde pueda recibir correo.</p>

            <form method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <Input
                            name="displayName"
                            control={control}
                            label="Nombre"
                            defaultValue={user?.displayName}
                        />
                    </div>
                    <div className="sm:col-span-3">
                        <Input
                            name="email"
                            control={control}
                            label="Correo electrónico"
                            defaultValue={user?.email}
                        />
                    </div>

                    {/* <div className="sm:col-span-3">
                        <Input
                            name="firstName"
                            control={control}
                            label="Nombre"
                            defaultValue={user?.firstName}
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <Input
                            name="lastName"
                            control={control}
                            defaultValue={user?.lastName}
                            label="Apellido"

                        />
                    </div>
                    <div className="sm:col-span-3">
                        <Input
                            name="number"
                            control={control}
                            defaultValue={() => {
                                if (user?.phones && user?.phones.length >= 1) {
                                    return user?.phones[0].number
                                }
                            }}
                            label="Número de teléfono"

                        />
                    </div>

                    <div className="sm:col-span-3">
                        <Select
                            onChange={undefined}
                            label="Sexo"
                            data={[{
                                name: "Masculino",
                                id: "male",
                            }, {
                                name: "Femenino",
                                id: "female",
                            }]}
                            name="sex"
                            control={control}
                            defaultValue={user?.sex}
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <Input
                            name="birthAt"
                            control={control}
                            defaultValue={user?.birthAt}
                            label="Fecha de cumpleaños"
                        />
                    </div>

                    <div className="col-span-full">
                        <Input
                            name="street_1"
                            control={control}
                            defaultValue={user?.address?.street_1!}
                            label="Calle 1"
                        />
                    </div>
                    <div className="col-span-full">
                        <Input
                            name="street_2"
                            control={control}
                            defaultValue={user?.address?.street_2!}
                            label="Calle 2"
                        />
                    </div>

                    <div className="sm:col-span-3 sm:col-start-1">
                        <AsyncComboBox
                            name="countryId"
                            label="País"
                            control={control}
                            dataQuery={{ url: "/public/countries" }}
                            normalizeData={{ id: "id", name: "name" }}
                            defaultItem={user?.address?.country ? { id: user.address.country.id, name: user.address.country.name } : undefined}
                            defaultValue={user?.address?.country?.id!}
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <AsyncComboBox
                            name="provinceId"
                            label="Provincia"
                            control={control}
                            dataQuery={{ url: "/public/provinces" }}
                            normalizeData={{ id: "id", name: "name" }}
                            defaultItem={user?.address?.province ? { id: user.address.province.id, name: user.address.province.name } : undefined}
                            dependendValue={{ countryId: watch("address.countryId") ?? getValues("address.countryId") }}
                            defaultValue={user?.address?.province?.id!}
                        />
                    </div>

                    <div className="sm:col-span-3">
                        <AsyncComboBox
                            name="municipalityId"
                            label="Municipio"
                            control={control}
                            dataQuery={{ url: "/public/municipalities" }}
                            normalizeData={{ id: "id", name: "name" }}
                            defaultItem={user?.address?.municipality ? { id: user.address.municipality.id, name: user.address.municipality.name } : undefined}
                            dependendValue={{ provinceId: watch("address.provinceId") ?? getValues("address.provinceId") }}
                            defaultValue={user?.address?.municipality?.id!}
                        />
                    </div>

                    <div className="sm:col-span-2">
                        <Input
                            name="city"
                            control={control}
                            defaultValue={user?.address?.city!}
                            label="Ciudad"
                        />
                    </div> */}
                </div>
                <div>
                    <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-blue-950 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-cyan-500 hover:bg-cyan-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >
                        {isFetching ? "Cargando..." : "Actualizar información"}
                    </button>
                </div>
            </form>


        </div>
    )
}

export default EditProfile
