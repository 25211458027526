import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ResultState {
    value: boolean
    searchInputValue: string
}

const initialState: ResultState = {
    value: false,
    searchInputValue: ""
}

export const resultState = createSlice({
    name: 'resultState',
    initialState,
    reducers: {
        setResultState: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload
        },
        setSearchInputValue: (state, action: PayloadAction<string>) => {
            state.searchInputValue = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setResultState, setSearchInputValue } = resultState.actions

export default resultState.reducer