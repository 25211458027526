import { createSlice } from "@reduxjs/toolkit";
import { OrderInterface } from "../../interfaces/ServerInterfaces";

interface PaginateInterface {
  totalItems: number;
  totalPages: number;
  currentPage: number;
}
interface InitialInterface {
  orders: OrderInterface[] | undefined;
  detailedOrders: OrderInterface[];
  //-----------------------------
  ordersPaginate: PaginateInterface | null;
  orderCompleted: OrderInterface | null;
  clearCarShop: boolean;
  couponDetails: Array<{ amount: number, codeCurrency: string }> | null;
  couponDetailsName: string | null;
}

const initialState: InitialInterface = {
  orders: [],
  detailedOrders: [],
  //--------------------------
  ordersPaginate: null,
  orderCompleted: null,
  clearCarShop: false,
  couponDetails: null,
  couponDetailsName: null,
};

const ordersSlice = createSlice({
  initialState,
  name: "orders",
  reducers: {
    addDetailedOrder: (state, action) => ({
      ...state,
      detailedOrders: [...state.detailedOrders, { ...action.payload }],
    }),
    setOrders: (state, action) => ({
      ...state,
      orders: action.payload
    }),
    setOrdersPaginate: (state, action) => ({
      ...state,
      ordersPaginate: action.payload
    }),
    setOrderCompleted: (state, action) => ({
      ...state,
      orderCompleted: action.payload
    }),
    setCouponDetails: (state, action) => ({
      ...state,
      couponDetails: action.payload
    }),
    setCouponDetailsName: (state, action) => ({
      ...state,
      couponDetailsName: action.payload
    }),
    setClearCarShop: (state, action) => ({
      ...state,
      clearCarShop: action.payload
    }),
  },
});

export const { addDetailedOrder, setOrders,
  setOrderCompleted,
  setCouponDetails,
  setCouponDetailsName,
  setClearCarShop,
  setOrdersPaginate
} = ordersSlice.actions;

export default ordersSlice.reducer;
