import { useContext } from "react";
import { ProductInfoContex } from "./modals/modalProduct/ModalProduct";
import { AvailableAddonInterface } from "../interfaces/ServerInterfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SelectCoccionTerm = ({ addons }: { addons: AvailableAddonInterface[] }) => {

  const {
    CoccionTerms,
    setCoccionTerms,
  } = useContext(ProductInfoContex);

  return (
    <div>
      <h2 className='font-lg text-black font-semibold text-md mt-2'>Término de cocción</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2 w-full">
        {
          addons.map((addon) => (
            <div
              className="flex cursor-pointer hover:opacity-60 justify-between w-full min-h-10 h-min-10 h-auto my-1 items-center bg-gradient-to-r from-slate-200 to-slate-100 rounded-md"
              onClick={() => {
                if (CoccionTerms === addon.name) {
                  setCoccionTerms("")
                } else {
                  setCoccionTerms(addon.name)
                }
              }}
            >
              <div className='flex items-center justify-between w-full px-10'>
                {/* <div className={`h-5 w-5 bg-${addon.name} rounded-full mr-4`}></div> */}
                <p className={`capitalize text-lg my-1`}>{addon.name}</p>

                {
                  CoccionTerms === addon.name && (
                    <div>
                      <FontAwesomeIcon
                        icon={
                          faCheck
                        }
                        className="mx-2 text-cyan-500 w-full"
                        size="xl"
                        aria-hidden="true"
                      />
                    </div>
                  )
                }


              </div>
            </div>
          ))
        }
      </div>

    </div>
  )
}

export default SelectCoccionTerm
