/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ProductInterface } from "../../../interfaces/ServerInterfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import imageDefault from "../../../assets/jpg/no-imagen-shop.jpg"
import { printPrice, printPriceWithCommasAndPeriods } from "../../../utils/functions";
import ModalProduct from "./ModalProduct";

interface Props {
  onClick: Function;
  multipleProduct: {
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  };
  addCart: Function;
  includeShop: boolean;
  cakeCustomizationData: any;
  volcanicCandelData: any;
  ceramicCandelData: any;
  when_shop_create_preorder: boolean;
  enable_to_sale_in_negative: boolean;
}


const ModalMultiProduct = ({ onClick, multipleProduct, addCart, includeShop, cakeCustomizationData,
  volcanicCandelData,
  ceramicCandelData,
  when_shop_create_preorder,
  enable_to_sale_in_negative
}: Props) => {

  const [showModalMultiProduct, setShowModalMultiProduct] = useState<boolean>(false);

  const [selectedMultiProduct, setSelectedMultiProduct] = useState<{
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  }>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  const showMultiProduct = (value: {
    repeatedWord: string;
    repeatedProducts: ProductInterface[];
  }) => {
    setSelectedMultiProduct(value);
    setShowModalMultiProduct(true);
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => onClick()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-60"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-20"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex  px-5 py-32 w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">

                <div className="rounded-md relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">

                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={() => onClick()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>


                  <div className="flex flex-col md:grid md:grid-cols-2 px-20 items-center justify-center w-full overflow-y-scroll scrollbar-hide">

                    <h2 className="text-xl mb-5 col-span-2 font-semibold self-center text-center">{multipleProduct.repeatedWord === "Yogurt" ? "Seleccione su porción" : "Seleccione un tipo de producto"}</h2>

                    {multipleProduct.repeatedWord === "Yogurt"
                      ?
                      [
                        { name: "1/2 Litro (500 ml)", code: "500ml" },
                        { name: "1 Litro", code: "1L" },
                        { name: "4 Litros", code: "4L" },
                        { name: "Vaso (Servicio a la mesa)", code: "220ml" },
                        { name: "Vaso 8oz (Para llevar)", code: "8oz" },
                      ].map(service => {
                        if (multipleProduct?.repeatedProducts?.some(prod => prod.name.includes(service.code))!) {
                          return (
                            <div
                              className="flex items-center justify-between w-64 my-2 cursor-pointer border-slate-100 border-2 shadow-sm rounded-md p-2"
                              onClick={() => showMultiProduct({
                                repeatedProducts: multipleProduct?.repeatedProducts?.filter(prod => prod.name.includes(service.code))!,
                                repeatedWord: service.code
                              })}
                            >
                              <p className="ml-5 font-semibold text-md">{service.name}</p>

                              <FontAwesomeIcon icon={faChevronRight} className="opacity-50 mx-2 text-gray-500 cursor-pointer hover:opacity-100"
                                size={"1x"}
                              />
                            </div>
                          )
                        }


                      })

                      : multipleProduct.repeatedProducts.map(product => (
                        <div
                          className="flex items-center justify-start w-64 my-2 cursor-pointer border-slate-100 border-2 shadow-sm rounded-md p-2"
                          onClick={() => showProduct(product)}
                        >
                          <img
                            src={
                              product?.images
                                .length !==
                                undefined &&
                                product?.images.length >=
                                1
                                ? product?.images[0]
                                  .src
                                : imageDefault
                            }
                            alt={product?.name}
                            className="h-20 w-20 object-cover object-center rounded-md"
                          />
                          <p className="ml-5 font-semibold text-md">{product.name}</p>

                          <p
                            className={`text-2xl mr-2 text-gray-900`}
                          >
                            {printPriceWithCommasAndPeriods(product?.onSalePrice !== null ? product?.onSalePrice?.amount : product?.prices[0].price) +
                              " " +
                              product?.onSalePrice !== null ? product?.onSalePrice?.codeCurrency : product?.prices[0].codeCurrency}
                          </p>

                          <FontAwesomeIcon icon={faChevronRight} className="opacity-50 mx-2 text-gray-500 cursor-pointer hover:opacity-100"
                            size={"1x"}
                          />

                        </div>
                      ))}
                  </div>


                  {showModal && (
                    <ModalProduct
                      product={seletedProduct}
                      onClick={() => setShowModal(false)}
                      addCart={addCart}
                      includeShop={includeShop}
                      cakeCustomizationData={cakeCustomizationData}
                      volcanicCandelData={volcanicCandelData}
                      ceramicCandelData={ceramicCandelData}
                      when_shop_create_preorder={when_shop_create_preorder}
                      enable_to_sale_in_negative={enable_to_sale_in_negative}
                    />
                  )}

                  {showModalMultiProduct && (
                    <ModalMultiProduct
                      multipleProduct={selectedMultiProduct!}
                      onClick={() => setShowModalMultiProduct(false)}
                      addCart={addCart}
                      includeShop={includeShop}
                      cakeCustomizationData={cakeCustomizationData}
                      volcanicCandelData={volcanicCandelData}
                      ceramicCandelData={ceramicCandelData}
                      when_shop_create_preorder={when_shop_create_preorder}
                      enable_to_sale_in_negative={enable_to_sale_in_negative}
                    />
                  )}


                </div>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalMultiProduct
